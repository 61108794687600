var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      [
        _vm.items && _vm.items.length > 0
          ? _c(
              "div",
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "v-card",
                  {
                    key: index,
                    staticClass: "my-4 rounded-lg border",
                    attrs: {
                      elevation: "0",
                      hover: "",
                      ripple: "",
                      to: `/c/questions/${item.id}`,
                    },
                  },
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center",
                      },
                      [
                        _c("div", [
                          item.answers
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.answers.length) +
                                      " " +
                                      _vm._s(_vm.$t("Answer"))
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12",
                                },
                                [_vm._v(" 0 " + _vm._s(_vm.$t("Answer")))]
                              ),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "f13 font-weight-light" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("moment")(
                                    item.createDate,
                                    "from",
                                    "now"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("v-card-text", [
                      _c("p", {
                        staticClass: "f14 text-justify",
                        domProps: { innerHTML: _vm._s(item.question) },
                      }),
                    ]),
                    _c("v-divider", {
                      staticClass: "m-0",
                      attrs: { dark: "" },
                    }),
                    _c(
                      "v-card-actions",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center",
                      },
                      [
                        item.answers && item.answers.length
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row align-items-center",
                                },
                                [
                                  _c(
                                    "section",
                                    {
                                      staticClass:
                                        "d-flex flex-row avatar-group right-to-left",
                                    },
                                    _vm._l(
                                      item.answers.slice(0, 3),
                                      function (answer, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            staticClass:
                                              "avatars-group__item avatar",
                                          },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "bordered",
                                                attrs: {
                                                  size: "36",
                                                  color: "primary",
                                                },
                                              },
                                              [
                                                answer.doctorProfilePic &&
                                                index < 2
                                                  ? _c("img", {
                                                      attrs: {
                                                        src: answer.doctorProfilePic,
                                                        alt: answer.doctorFullName
                                                          ? answer.doctorFullName
                                                          : "",
                                                      },
                                                    })
                                                  : index < 2
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "white--text text-small",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              answer.doctorFullName
                                                                ? answer.doctorFullName.slice(
                                                                    0,
                                                                    1
                                                                  )
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "white--text text-small",
                                                      },
                                                      [_vm._v(" ... ")]
                                                    ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c("div", [
                                    item.answers.length == 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "f12 font-weight-normal ps-1",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.answers[0].doctorFullName
                                                    ? item.answers[0]
                                                        .doctorFullName
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.answers.length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "f12 font-weight-normal ps-1",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.answers.length) +
                                                " " +
                                                _vm._s(_vm.$t("specialist")) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ])
                          : _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "bg-harmonies text-paris-pink p-2 rounded-lg f12",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Not answered")) + " "
                                  ),
                                ]
                              ),
                            ]),
                        _c("div", [
                          _c("div", { staticClass: "text-left mt-1" }, [
                            item.userLikeQuestions &&
                            !item.userLikeQuestions.find(
                              (x) => x.mobile == _vm.$store.getters.mobileNumber
                            )
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex d-inline-flex align-items-center border border-primary rounded primary--text",
                                  },
                                  [
                                    _c("iconly", {
                                      staticClass: "ms-1 me-0 wh-20",
                                      attrs: { type: "bold", name: "heart" },
                                    }),
                                    item.userLikeQuestions &&
                                    item.userLikeQuestions.length
                                      ? _c(
                                          "span",
                                          { staticClass: "me-1 f12" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.userLikeQuestions.length
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "me-1 f12" },
                                          [_vm._v("0")]
                                        ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex d-inline-flex align-items-center primary rounded white--text",
                                  },
                                  [
                                    _c("iconly", {
                                      staticClass: "ms-1 me-0 wh-20",
                                      attrs: {
                                        type: "bold",
                                        name: "heart",
                                        color: "white",
                                      },
                                    }),
                                    item.userLikeQuestions &&
                                    item.userLikeQuestions.length
                                      ? _c(
                                          "span",
                                          { staticClass: "me-1 f12" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.userLikeQuestions.length
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "me-1 f12" },
                                          [_vm._v("0")]
                                        ),
                                  ],
                                  1
                                ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2",
                              },
                              [
                                _c("iconly", {
                                  staticClass: "ms-1 me-0 wh-20",
                                  attrs: { type: "bold", name: "show" },
                                }),
                                _c("span", { staticClass: "me-1 f12" }, [
                                  _vm._v(_vm._s(item.view)),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
              _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
            ]),
        _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
          _c(
            "div",
            { attrs: { slot: "spinner" }, slot: "spinner" },
            [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "no-more" }, slot: "no-more" },
            [_c("v-icon", [_vm._v("mdi-access-point-off")])],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "no-results" }, slot: "no-results" },
            [_c("v-icon", [_vm._v("mdi-access-point-off")])],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }